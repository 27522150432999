import LayoutAdmin from '@/layouts/LayoutAdmin'
import myTable from './table'
let urutan = 0
export default {
  data(){
    return {
      setDefaultBalai:'all',
      setSubTitle:'',
      listBalai: [],
      search: '',
      loadingTb:false,
      isActive: [],
      options: {},
      optionsDJSDA: {},
      optionsDIPA: {},
      optionsKumulatif:{},
      optionsDIPA2:{},
      isLoaderProfile:false,
      profilLoan:{
        "registerId": "-",
        "registerNm": "-",
        "lender": "-",
        "executingAgency": "-",
        "implementingAgency": "-",
        "tanggalEfektif": "-",
        "totalPinjaman": 0,
        "totalDipa": 0,
        "totalBulan": "-",
        "totalBulanTerpakai": "-",
        "totalBulanTerpakaiPrs": "-",
        "lokasi": "-",
        "statusKegiatan": "-",
        "pv": "-"
      },
      summary:[{
        "jumlahPinjaman" : 0,
        "realisasiKumulatif" : 0,
        "paguDipa" : 0,
        "realisasiDipa" : 0,
        "countAtRisk" : 0,
        "countBehindSchedule" : 0,
        "countOnSchedule" : 0,
        "prsDipa" : 0,
        "prsKumulatif" : 0
      }
        
      ],
      yearFilter:[],
      pieChartJumlah:[],
      pieChartJumlahDJSDA:[],
      pieChartJumlahDIPA:[], 
      pieChartJumlahDIPA2:[],      
      pieChartJumlahKumulatif:[], 
      pieChartTotal:[],
      tableSatker:[],
      tableDetailPaket:[],
      tbData:[],
			data_color :["#00C5DC","#F4516C","#FFB822","#8859E0","#0C5484","#66BB6A","#00838F","#e57373"],
      data_color_reverse :["#F4516C","#00C5DC"],
      seriesTotal: [],
      seriesTotalDIPA: [],
      optionsDJSDAbar: {},
      optionsDIPAbar: {},
      penyerapanTotal:[],
      seriesPenyerapan:[],
      penyerapanOption:{},
      penyerapanTotalAkumulasi:[],
      penyerapanOptionAkumulasi:{},
      istableDetail:false,
      titleDetail:null,
      tableDetailPaketAll:[],
      setUrut:0,
      listRegister:[],
      listPilihLoan:[]
     
    }
},
   created() {
    this.$emit(`update:layout`, LayoutAdmin);   
  },
  mounted(){

    this.G_GetRef('balai').then(data => {
      this.listBalai = data;
      this.listBalai.unshift({
        'text': 'Semua Balai',
        'value': 'all'
      })

    this.setDefaultBalai = 'all'

    });

    this.$nextTick(() => {
      $('.selectpicker').selectpicker('render');
      this.getSummary('init');
    })

   
    
  },
  components: {
      myTable
  },
  methods:{
    getListTahun() {
      for (let index = this.G_yearNow; index >= (this.G_yearNow-2); index--) {
        this.yearFilter.push(index);
      }
    },
    cariData(){
      this.getSummary('filter');
    },
    toggleSub(key){
      this.isActive[key] = !this.isActive[key];

      if (this.isActive[key]) {
        $('#sub_'+key).hide();
      }else{
        $('#sub_'+key).show();

      }

    },
    cariDataProfilLoan(){
      this.isLoaderProfile = true
      this.G_GetAny('/dashboard/profil/loan/'+this.$refs.register.getValue()).then(res => {

        if (res.status == 500) {
            this.isLoaderProfile = false

            this.$refs.notif.warning(res.data.message);
        }else{
            this.profilLoan = res.data

            this.pieChartJumlahDIPA2 = res.data.realisasiDipa
            this.optionsDIPA2 = {
              legend : {
                show:true,
                position:"bottom"
              },
              colors:  this.data_color,
              dataLabels: {
                enabled: true,
              },
              labels: this.pieChartJumlahDIPA2.label,
            
              plotOptions: {
                pie: {
                  customScale: 1,
                  donut: {
                    labels: {
                      show: true,
                      name: {
                        show:true,
      
                      },
                      value: {
                        formatter: function (value) {
                          let val = (value / 1).toFixed(0).replace('.', ',')
                          return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                        },
                        show:true,
                      },
                      total: {
                        formatter: function (w) {
                          let setTotal = w.globals.seriesTotals.reduce((a, b) => a + b, 0)
                          let val = (setTotal / 1).toFixed(0).replace('.', ',')
                          return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                        },
                        show:true,
                      }
      
                    }
                  }
                }
              }
            }
    
            this.pieChartJumlahKumulatif = res.data.realisasiKumulatif
            this.optionsKumulatif = {
              legend : {
                show:true,
                position:"bottom"
              },
              colors:  this.data_color,
              dataLabels: {
                enabled: true,
              },
              labels: this.pieChartJumlahKumulatif.label,
            
              plotOptions: {
                pie: {
                  customScale: 1,
                  donut: {
                    labels: {
                      show: true,
                      name: {
                        show:true,
      
                      },
                      value: {
                        formatter: function (value) {
                          let val = (value / 1).toFixed(0).replace('.', ',')
                          return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                        },
                        show:true,
                      },
                      total: {
                        formatter: function (w) {
                          let setTotal = w.globals.seriesTotals.reduce((a, b) => a + b, 0)
                          let val = (setTotal / 1).toFixed(0).replace('.', ',')
                          return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                        },
                        show:true,
                      }
      
                    }
                  }
                }
              }
            }
    
            this.isLoaderProfile = false
        }
       
       

      })
    },
    getSummary(type){
      let filter = '';
     
      filter += '?tahun=2022'
      // filter += '?kdbalai=2022'
      this.setSubTitle = 'Semua Balai'

      if (type === 'filter') {
        this.setSubTitle = 'Semua Balai'
        if (this.$refs.balai.getValue() != 'all'){
          this.setSubTitle = this.listBalai[this.$refs.balai.getValue()].text
          filter += '&kdbalai='+this.$refs.balai.getValue()
        }
      }

     
      

      this.G_GetAny('dashboard/summary'+filter).then(res => {
        this.summary = res.data
      })

      this.G_GetAny('kontraktual/summary/byStatus/count/pie'+filter).then(res => {
        this.pieChartJumlah = res.data
        this.options = {
          legend : {
            show:true,
          },
          colors:  this.data_color,
          dataLabels: {
            enabled: true,
          },
          labels: this.pieChartJumlah.label,
         
          plotOptions: {
            pie: {
              customScale: 1,
              donut: {
                labels: {
                  show: true,
                  name: {
                    show:true,
  
                  },
                  value: {
                    show:true,
                  },
                  total: {
                    show:true,
                  }
  
                }
              }
            }
          }
        }
      })

      this.G_GetAny('/dashboard/pelaksanaan-pinjaman/pie'+filter).then(res => {
        
        let revertData = res.data.data.reverse()
        let revertLabel = res.data.label.reverse()

        this.pieChartJumlahDJSDA = revertData

        this.optionsDJSDA = {
          height:100,
          legend : {
            show:true,
          },
          colors:  this.data_color_reverse,
          dataLabels: {
            enabled: true,
          },
          labels: revertLabel,
         
          plotOptions: {
            pie: {
              customScale: 1,
              donut: {
                labels: {
                  show: true,
                  name: {
                    show:true,
  
                  },
                  value: {
                    formatter: function (value) {
                      let val = (value / 1).toFixed(0).replace('.', ',')
                      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                    },
                    show:true,
                  },
                  total: {
                    formatter: function (w) {
                      let setTotal = w.globals.seriesTotals.reduce((a, b) => a + b, 0)
                      let val = (setTotal / 1).toFixed(0).replace('.', ',')
                      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                    },
                    show:true,
                  }
  
                }
              }
            }
          }
        }
      })

      this.G_GetAny('dashboard/penyerapan-dipa/pie'+filter).then(res => {

        let revertData = res.data.data.reverse()
        let revertLabel = res.data.label.reverse()

        // revertData[0] = revertData[0] - revertData[1]

        this.pieChartJumlahDIPA = revertData
        this.optionsDIPA = {
          legend : {
            show:true,
          },
          colors:  this.data_color_reverse,
          dataLabels: {
            enabled: true,
          },
          labels: revertLabel,
         
          plotOptions: {
            pie: {
              customScale: 1,
              donut: {
                labels: {
                  show: true,
                  name: {
                    show:true,
  
                  },
                  value: {
                    formatter: function (value) {
                      let val = (value / 1).toFixed(0).replace('.', ',')
                      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                    },
                    show:true,
                  },
                  total: {
                    formatter: function (w) {
                      let setTotal = w.globals.seriesTotals.reduce((a, b) => a + b, 0)
                      let val = (setTotal / 1).toFixed(0).replace('.', ',')
                      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                    },
                    show:true,
                  }
  
                }
              }
            }
          }
        }
      })

      this.G_GetAny('dashboard/pelaksanaan-pinjaman/bar'+filter).then(res => {
     
        let setSeries = res.data.series 
        let setLabel = res.data.label
        let tmpSeries = []

        setSeries.reverse().forEach((v,k) => {
          if (k == 1) {
            let tmpData = []
            v.data.forEach((vd,kd) => {
              tmpData.push(vd - tmpSeries[0].data[kd]) 
            });
            tmpSeries.push(
              {
                name: v.name,
                data: tmpData
              }
            )
          }else{
            tmpSeries.push(
              {
                name: v.name,
                data: v.data
              }
            )
          }
        });

        this.seriesTotal = tmpSeries
          this.optionsDJSDAbar = {
          chart: {
            type: 'bar',
            height: 500,
            stacked: true,
              toolbar: {
                show: true
              },
          },
          plotOptions: {
            bar: {
              borderRadius: 10,
              horizontal: false,
              dataLabels: {
                position: 'bottom'
              }
            },
          },
          dataLabels: {
            enabled: true,
            enabledOnSeries:[0],
            style: {
              fontSize: '12px',
          },
            formatter: function (val,opt) {
             
              let goals = setSeries[1].data[opt.dataPointIndex];
              let persentase = (val) ? (val /goals)*100 : 0
              return `${persentase.toFixed(2)}%`
            }
          },
          
          colors: ['#F4516C','#039be57d'],
          yaxis: [{
            labels: {
              formatter: function (value,index) {
                let val = (value / 1).toFixed(0).replace('.', ',')
                return "Rp "+ val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
              }
            },
          }],
          xaxis: {
            categories: setLabel,
          },
          legend: {
            show: true,
           
          },
        }
      })

      this.G_GetAny('dashboard/penyerapan-dipa/bar'+filter).then(res => {
        let setSeries = res.data.series 
        let setLabel = res.data.label
        let tmpSeries = []

        setSeries.reverse().forEach((v,k) => {
          if (k == 1) {
            let tmpData = []
            v.data.forEach((vd,kd) => {
              tmpData.push(vd - tmpSeries[0].data[kd]) 
            });
            tmpSeries.push(
              {
                name: v.name,
                data: tmpData
              }
            )
          }else{
            tmpSeries.push(
              {
                name: v.name,
                data: v.data
              }
            )
          }
        });

        this.seriesTotalDIPA = tmpSeries
          this.optionsDIPAbar = {
          chart: {
            type: 'bar',
            height: 500,
            stacked: true,
              toolbar: {
                show: true
              },
          },
          plotOptions: {
            bar: {
              borderRadius: 10,
              horizontal: false,
              dataLabels: {
                position: 'bottom'
              }
            },
          },
          dataLabels: {
            enabled: true,
            enabledOnSeries:[0],
            style: {
              fontSize: '12px',
          },
            formatter: function (val,opt) {
             
              let goals = setSeries[1].data[opt.dataPointIndex];
              let persentase = (val) ? (val /goals)*100 : 0
              return `${persentase.toFixed(2)}%`
            }
          },
          
          colors: ['#F4516C','#039be57d'],
          yaxis: [{
            labels: {
              formatter: function (value,index) {
                let val = (value / 1).toFixed(0).replace('.', ',')
                return "Rp "+ val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
              }
            },
          }],
          xaxis: {
            categories: setLabel,
          },
          legend: {
            show: true,
           
          },
        }
        
      })
    
    },
    setNoUrut(idx){
      urutan++ 
      return urutan
    },
  }
}